/** @format */

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}

.orangeTitle {
  color: #de9e36;
  font-size: 24px;
  font-family: "Raleway", sans-serif;
}
.blueTitle {
  color: #254486;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  max-width: 60rem;
  align-items: center;
  font-family: "Raleway", sans-serif;
  margin: auto;
}

.hoverText {
  color: initial;
  cursor: pointer;
}

.wide-content {
  max-width: 1500px;
}

.title {
  font-size: 34px;
  font-family: "adobe-garamond-pro", serif;
}
.section_title {
  font-size: 48px;
  font-weight: 500;
  font-family: "adobe-garamond-pro", serif;
  line-height: 1.1;
}
.privacy_policy,
.reviews {
  font-size: 20px;
  font-weight: 500;
  font-family: "adobe-garamond-pro", serif;
  line-height: 1.1;
}

.reviews p,
.reviews h2 {
  font-family: "Raleway";
}

.practice_article .section_title {
  font-size: 36px;
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
}

.blue_quote {
  width: 100%;
  font-family: "Raleway";
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  margin: 20px 0;
  text-align: center;
  letter-spacing: 0.15px;

  /* Primary/Dark */

  color: #254486;
}
.garamond {
  font-family: "adobe-garamond-pro", serif;
}

.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 14px)
  ) !important;
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 14px)
  ) !important;
  display: inline-block;
  border-radius: 50%;
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(
    --swiper-pagination-color,
    var(--swiper-theme-color)
  ) !important;
  background-color: #254486 !important;
}

#custom-live-chat {
  position: fixed;
  z-index: 9999;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 1s;
}

#custom-live-chat.maximized {
  opacity: 0;
}

/* Make default live chat always visible */
/* .show-live-chat #chat-widget-container {
  visibility: visible!important;
  z-index: auto!important;
  opacity: 1!important;
} */

.fake_video {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 64px;
  transition: all 0.3s;
}

.fake_video:hover {
  color: #de9e36;
}

.fake_video img {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  max-width: 100%;
  width: auto;
}

@media screen and (max-width: 768px) {
  .fake_video img {
    max-width: 50%;
    height: auto;
  }
}

.fake_video img:hover {
  filter: brightness(110%);
  z-index: 9999;
}

.raleway {
  font-family: "Raleway", sans-serif !important;
}

.ant-input-lg {
  padding: 15px !important;
}
.ant-collapse-item {
  /* border-bottom: 1px solid #dfdfdf !important; */
}
/*
.ant-collapse {
  border: none !important;
  box-shadow: none !important;
  background-color: none !important;
} */

/* Add a black background color to the top navigation */
.topnav {
  background-color: #254486;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Style the links inside the navigation bar */
.topnav a {
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 2px solid #314c86;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .topnav a {
    padding: 14px 24px;
  }
}
@media screen and (min-width: 1360px) {
  .topnav a {
    padding: 14px 32px;
  }
}
/* Add an active class to highlight the current page */
.active {
  background-color: #314c86;
  color: #de9e36;
  text-decoration: underline;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}

/* Dropdown container - needed to position the dropdown content */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  border-bottom: 2px solid #314c86;
  transition: all 0.3s;
}

.dropbtn:hover {
  border-bottom: 2px solid #de9e36;
  cursor: pointer;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #314c86;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* padding: 8px 32px;*/
  padding: 8px 20px;
}

/* Style the links inside the dropdown */
.dropdown-content a {
  float: none;
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  border-bottom: 2px solid #314c86;
}

/* Add a dark background on topnav links and the dropdown button on hover */
.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: #314c86;
  color: #de9e36;
  border-bottom: 2px solid #314c86;
}

/* Add a grey background to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #314c86;
  color: #de9e36;
  border-bottom: 2px solid #314c86;
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
}

.disabled_menu_item {
  float: none;
  color: rgb(162, 161, 161);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 16px;
}

.ant-btn {
  box-shadow: none !important;
}

.ant-breadcrumb-link {
  font-weight: 600;
  color: #000 !important;
}

.ant-steps-item-wait .ant-steps-item-container .ant-steps-item-icon {
  background: rgba(0, 0, 0, 0.38);
}
.ant-steps-item-wait .ant-steps-item-container .ant-steps-item-icon span {
  color: #fff !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 55px;
}

.ant-select-single.ant-select-lg
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 55px;
}

.firm-features {
  width: 90%;
}

.client_mobile_device_text {
  width: 90%;
}

@media (max-width: 640px) {
  #custom-live-chat {
    right: 0;
  }
  .client_mobile_device_text {
    width: 70vw;
  }
  .orangeTitle {
    font-size: 20px;
    font-weight: 600;
  }
  .block-template-left p img {
    max-width: 100% !important;
  }
  .section_title {
    margin-top: 10px;
    font-size: 34px;
    font-family: "Raleway";
    font-weight: 400;
  }
  #faq_search::placeholder {
    color: #fff;
    opacity: 0.6;
  }
  .ant-input::placeholder {
    color: black !important; /* Change the color to your desired color */
    opacity: 0.6 !important; /* Change the opacity to your desired opacity */
  }

  .stext {
    text-align: left !important;
  }
  .stext1 {
    flex-wrap: wrap-reverse;
  }
  .stext3 {
    padding-top: 50px !important;
  }
  span.ant-collapse-header-text {
    padding-right: 10px;
  }
  .stext7 {
    text-align: center;
  }
}
@media (min-width: 728px) {
  .stext2 {
    display: none;
  }
}
@media (min-width: 1440px) {
  .firm-features {
    width: 80%;
  }
}
@media (min-width: 1660px) {
  .firm-features {
    width: 70%;
  }
}
.hoverable-tile {
  box-shadow: 0 0 0 5px 10px black;
  background-color: #254486 !important;
  transition: 0.5s ease-in-out;
}
.hoverable-tile:hover {
  background-color: #de9e36 !important;
}
.selecttile {
  /* box-shadow: 0 0 0 5px 10px black; */
  background-color: #de9e36 !important;
  transition: 0.5s ease-in-out;
}

.ant-tooltip-content {
  backdrop-filter: blur(2px);
}

.ant-tooltip-inner {
  width: 60vw;
}

.wp ul li {
  position: relative;
  padding-left: 10px;
}
.wp ul li::before {
  vertical-align: middle;
  content: "";
  font-weight: bold;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: cover;
  background-image: url("/bullet.png");
  background-position: center center;
  position: absolute;
  top: 3px;
  left: -30px;
}

.wp.criminal-tax-defence-post-content-area,
.wp.criminal-tax-defence-post-content-area p,
.wp.taxbankruptcy-font-size,
.wp.taxbankruptcy-font-size p,
.wp.taxcontroversy-font-size,
.wp.taxcontroversy-font-size p {
  font-size: 16px;
}
.wp.criminal-tax-defence-post-content-area ul {
  padding-left: 52px;
}
.wp.criminal-tax-defence-post-content-area ul li {
  padding: 12px 12px 12px 0;
}
.wp.criminal-tax-defence-post-content-area ul li:before {
  width: 12px;
  height: 12px;
  background-color: #000;
  background-image: none;
  left: -34px;
  top: 22px;
}

/* add a hover class */
.shover-show .shover {
  visibility: hidden;
  width: auto;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: -1rem;
}
.shover-show:hover .shover {
  visibility: visible;
}

.wp ul li {
  /* list-style-image: url(/bullet.png); */
  list-style: none;
}

.wp img {
  max-width: 800px;
}

.no-before::before {
  display: none !important;
}

.right_menu li {
  font-size: 16px !important;
}

.right_menu ul li span {
  font-size: 16px;
}

.right_menu .ant-menu-item-selected {
  background: rgba(37, 68, 134, 0.08) !important;
  color: #254486 !important;
}

.right_menu .ant-menu-submenu-selected .ant-menu-submenu-title:first-child {
  background: rgba(37, 68, 134, 0.08) !important;
  color: #254486 !important;
}

.right_menu .ant-menu-item:hover {
  background: rgba(37, 68, 134, 0.08) !important;
  color: #254486 !important;
}

.right_menu .ant-menu-submenu-title {
  padding: 12px !important;
  font-size: 20px !important;
}

.right_menu .ant-menu-item-only-child {
  padding: 12px !important;
  font-size: 20px !important;
}

.video_btn:hover {
  filter: brightness(130%);
}
.video_btn {
  transition: all 0.5 linear;
  cursor: pointer;
}

.ant-menu-title-content {
  font-weight: 900;
  font-size: 16px;
}

.faq_menu {
  justify-content: center !important;
}

/* .faq_menu li { */
/* margin-right: 70px !important; */
/* } */

.faq_menu li:nth-child(4) {
  margin-right: 0px !important;
}

.star .ant-form-item-required::before {
  font-size: 14px !important;
  margin-bottom: 15px;
}

:where(.css-dev-only-do-not-override-1h9hifu).ant-image .ant-image-img {
  object-fit: cover;
}
.stext4 {
  margin-top: 30px !important;
}
.stext5 {
  object-fit: contain !important;
}
.stext6 {
  object-fit: cover;
}
.leaflet-container {
  height: 300px;
  width: 100%;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 868 !important;
}
.copyRightText {
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-opacity: 1;
  color: "#fff";
  font-weight: "400";
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.my-custom-select .ant-select-selection-item {
  font-size: 20px;
  font-weight: 600;
  font-family: Raleway;
  line-height: 32px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}
.my-custom-option {
  color: red;
  font-size: 18px;
}

.my-custom-dropdown {
  background-color: white;
  position: absolute;
  bottom: "0px";
  right: "3px";
}
.White__BG_button {
  background: "#fff";
  color: "#254486";
  cursor: "pointer";
  outline: none;
}
._2eZHnh_PMhyzt7w_zi7 {
  min-height: 100% !important;
  overflow: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */

/* Hide scrollbar for IE, Edge and Firefox */
.Add__Height {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  min-height: 100% !important;
}
::placeholder {
  color: #7a7777 !important;
}
/* LLS PAGES */
.LLS__SectionOne__LeftSide {
  margin-top: 7rem !important;
}
.LLS__Tax__Defense__Lawyer {
  margin-top: 14rem !important;
}

@media screen and (min-width: 1430px) {
  .LLS__SectionOne__LeftSide {
    margin-top: 8rem !important;
    margin-left: 5.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .Form__Btn {
    display: "flex";
    flex-direction: column;
  }
  .For__Width {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .LLS__Tax__Defense__Lawyer {
    margin: 2rem 0rem !important;
  }
}
@media screen and (min-width: 1919px) {
  .LLS__SectionOne__LeftSide {
    margin-top: 10rem !important;
  }
  .LLS__Tax__Defense__Lawyer {
    position: relative;
    right: -1.5rem;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.linkTagColor {
  color: #000 !important;
}

.custom404 {
  height: 40vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.custom404 h1 {
  margin-bottom: 0 !important;
}

.imageCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageResponsive {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.taxBankruptcy p span {
  font-weight: 600;
}

.ant-steps-item {
  white-space: normal !important;
  overflow: visible !important;
}

.ant-steps-item-content {
  display: grid !important;
}

.ant-steps-item-icon {
  margin-bottom: 5px !important;
}

.ant-steps-item:last-child {
  flex: 1 !important;
}

.ant-steps-item-title::after {
  height: 0 !important;
}

.centerAlignText {
  text-align: center;
}

/* styling for wordPress ul content */
ul.diamond-icon {
  list-style: none;
  padding: 0;
}

/* styling for wordPress ul content */
ul.diamond-icon li {
  position: relative;
  padding: 10px 30px; /* Adjust padding as needed */
  line-height: 30px;
}

/* styling for wordPress ul li content */
ul.diamond-icon li::before {
  content: "";
  background: url(/bullet.png) no-repeat; /* Set background image */
  background-size: contain;
  display: inline-block;
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  position: absolute;
  left: 0;
  margin-top: 16px;
  transform: translateY(-50%);
}

/* styling for wordPress ul li content */
/* Style for child ul */
ul.child-list {
  list-style: none;
  margin-bottom: -15px; /* to reduce the gap after the li nested child */
  padding-left: 0px; /* Adjust padding as needed for indentation */
}

ul.child-list li {
  left: 10px;
}

ul.child-list li::before {
  content: "";
  background: url(/icons/material-symbols_black_square.svg) no-repeat; /* Set black square icon image */
  background-size: contain;
  display: inline-block;
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  position: absolute;
  margin-top: 16px;
  transform: translateY(-50%);
}

.ant-progress-text {
  display: table-row !important;
}

.stepsIconStyle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #52c41a;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
}

/* styling for wordPress em content */
.blueAndBold {
  color: #254486;
  font-weight: 600;
}

.imageContainer {
  max-width: 100%;
  height: auto;
}

.invisible-number { position: absolute; left: -9999px; top: auto; width: 1px; height: 1px; overflow: hidden; }
.text-center-with-icon{max-width: 720px; margin: 0 auto;}
.text-center-with-icon.estate-plans_with-icon{
  max-width: 600px;
}

.subtitle.subtitleRight { position: absolute; max-width: 50%; left: 12px; margin: 0; font-size: 16px; text-align: left; background: #005ca0; padding: 20px; bottom: -40px; }

.how-can-help-box{
  row-gap: 60px !important;
}
.ant-col .ant-card-meta-description {
    min-height: 50px;
}
@media only screen and (max-width:1366px){
  .ant-col .ant-card-meta-description {
      min-height: 75px;
  }
}
@media only screen and (max-width: 1440px){
  .how-can-help-box{
    row-gap: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .subtitle.subtitleRight { max-width: 100%; bottom: -40px; }
}