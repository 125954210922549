.mobileNavHolder
  display: flex
  justify-content: space-between
  align-items: center
  padding: 8px 16px
  gap: 24px

.mobileLogo
  display: inline-block
  img
    width: 100%
    max-width: 400px
    height: auto

.imageWrapper
  display: flex
  align-items: flex-start
  justify-content: center
  max-width: 100%
  height: auto

.bocaRatonDropdown
  position: relative
  .bocaRatonDropdownMenu
    position: absolute
    display: none
    flex-direction: column
    width: clamp(100px, 10vw, 180px)
    // background: rgb(37, 68, 134)
    background: #fff
    z-index: 1
    left: 20px
    padding: 10px 0
    top: 100%
    a
      display: flex
      width: 100%
      color: rgb(37, 68, 134)
      font-size: 17px
      background: none
      border: none
      &:hover
        color: #de9e36
  &:hover
    .bocaRatonDropdownMenu
      display: flex

.bocaRatonDropdown
  position: relative
  .customScrollable
    position: absolute
    display: none
    flex-direction: column
    width: clamp(100px, 10vw, 180px)
    overflow-y: auto
    max-height: 20vh
    background: #fff
    z-index: 1
    left: 20px
    padding: 10px 0
    top: 100%
    a
      display: flex
      width: 100%
      color: rgb(37, 68, 134)
      font-size: 17px
      background: none
      border: none
      &:hover
        color: #de9e36
  &:hover
    .customScrollable
      display: flex

.hideIcon
  i
    display: none
  li
    white-space: normal !important
    line-height: normal !important

.customIconWrapper
  position: absolute
  top: 50%
  inset-inline-end: 16px
  width: 13px
  color: currentcolor
  transform: translateY(-50%)
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),opacity 0.3s

.customIconWrapper::before
  content: ''
  position: absolute
  top: 50%
  right: 12px /* Adjust the value based on your design */
  transform: translateY(-50%)
  width: 30px /* Adjust the width to increase the clickable area */
  height: 35px /* Adjust the height to increase the clickable area */
  background: transparent
  pointer-events: auto

.antDcustomDropdownIcon
.antDcustomDropdownIconOpen
  top: 50%
  right: 16px
  width: 10px
  color: currentcolor
  transform: translateY(-50%)
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)


.antDcustomDropdownIcon::before,
.antDcustomDropdownIcon::after,
.antDcustomDropdownIconOpen::before,
.antDcustomDropdownIconOpen::after
  content: ''
  position: absolute
  width: 6px
  height: 1.5px
  background-color: currentcolor
  border-radius: 4px
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)


.antDcustomDropdownIcon::before
  transform: rotate(45deg) translateX(2.5px)


.antDcustomDropdownIcon::after
  transform: rotate(-45deg) translateX(-2.5px)



.antDcustomDropdownIconOpen::before
  transform: rotate(-45deg) translateY(2.5px)


.antDcustomDropdownIconOpen::after
  transform: rotate(45deg) translateY(2.5px)
